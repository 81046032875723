<template>
  <div :class="$style.categories">
    <div :class="$style.wrapper">
      <el-button
        :class="$style.button"
        @click="$router.push('categories/create')"
      >
        Создать категорию
      </el-button>
    </div>
    <div :class="$style.treeHeader">
      <div :class="$style.category">Название</div>
      <div :class="$style.content">
        <div :class="$style.url">Имя в URL</div>
        <div :class="$style.checkbox">
          Отображать <br />
          на сайте
        </div>
        <div :class="$style.checkbox">SEO-тексты</div>
      </div>
    </div>
    <el-tree
      :class="$style.tree"
      :data="treeData.children"
      node-key="id"
      default-expand-all
      draggable
      @node-drag-end="handleDragEnd"
    >
      <div :class="$style.node" slot-scope="{ node, data }">
        <div :class="$style.columns">{{ data.name }}</div>
        <div :class="$style.content">
          <div :class="$style.url">{{ data.slug }}</div>

          <div :class="$style.checkboxWrapper">
            <el-checkbox
              :class="$style.checkbox"
              :checked="data.isActive"
              size="large"
            />
          </div>
          <div :class="$style.checkboxWrapper">
            <el-checkbox
              :class="$style.checkbox"
              :checked="!!data.seoTextsIds"
              size="large"
            />
          </div>
          <div :class="$style.actions">
            <router-link
              :to="'categories/' + data.id + '/catalog'"
              :class="$style.catalogLink"
            >
              Каталог
            </router-link>
            <ActionButtons
              :view-link="mappedCategories.get(data.id).url"
              :edit-link="'categories/' + String(data.id)"
              @delete="remove(node, data)"
            />
          </div>
        </div>
      </div>
    </el-tree>
  </div>
</template>

<script>
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'
export default {
  components: {
    ActionButtons,
  },
  data() {
    return {
      treeData: [],
    }
  },
  created() {
    this.getTree()
  },
  methods: {
    getMappedCategories(children, route, categories) {
      children.forEach((category) => {
        categories.set(category.id, {
          url: `${route}/${category.slug}`,
        })
        if (category.children?.length) {
          route = route + '/' + category.slug
          this.getMappedCategories(category.children, route, categories)
          route = route.replace('/' + category.slug, '')
        }
      })
      return categories
    },

    async getTree() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ProductsCore.CategoriesActions.getTree()
      if (error) return
      this.treeData = value
      let categoriesMap = new Map()
      if (this.treeData.children.length > 0) {
        categoriesMap = this.getMappedCategories(
          this.treeData.children,
          `${this.$configData.addwine_link}catalogue/category`,
          categoriesMap,
        )
      }
      this.mappedCategories = categoriesMap

      loading.close()
    },
    async save(list) {
      const loading = this.$loading({
        lock: true,
      })
      const res =
        await delivery.ProductsCore.CategoriesActions.updateCategories(list)
      loading.close()
      if (res.error) return
    },
    handleDragEnd(draggingNode, dropNode, dropType) {
      if (dropType == 'none') return

      this.updateTree(
        draggingNode,
        dropType == 'inner' ? dropNode : dropNode.parent,
      )
    },
    async remove(node, data) {
      const parent = node.parent
      const children = parent.data.children || parent.data

      const index = children.findIndex((d) => d.id === data.id)
      const list = this.getTreeList(children[index], [])
      const conf = confirm(
        'Вы действительно хотите удалить ' + list.map((i) => i.name).join(', '),
      )
      if (conf) {
        const res = await Promise.all(
          list.map((item) =>
            delivery.ProductsCore.CategoriesActions.delete(item.id),
          ),
        )
        let isError = false
        res.forEach((i) => {
          if (i.error) {
            alert('Произошла ошибка удаления')
            isError = true
          }
        })

        if (!isError) {
          children.splice(index, 1)
        }
      }
    },

    getTreeList(node, list) {
      list.push(node)
      if (node?.children?.length) {
        node.children.forEach((item) => {
          return this.getTreeList(item, list)
        })
      }

      return list
    },
    updateTree(draggingNode, parentNode) {
      var updateList = []
      var orderField = 0
      if (parentNode.data instanceof Array) {
        parentNode.data.forEach((item) => {
          if (
            item.orderField != ++orderField ||
            item.id == draggingNode.data.id
          ) {
            item.parentId = 1
            item.orderField = orderField
            updateList.push(item)
          }
        })
      } else {
        draggingNode.data.parentId = parentNode.data.id
        if (parentNode?.childNodes?.length) {
          parentNode.childNodes.forEach((item) => {
            if (
              item.data.orderField != ++orderField ||
              item.data.id == draggingNode.data.id
            ) {
              item.data.orderField = orderField
              updateList.push(item.data)
            }
          })
        }
      }
      this.save(updateList)
    },
  },
}
</script>

<style lang="scss" module>
.categories {
  padding: 1rem;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    .button {
      margin-bottom: 1rem;
    }
  }
  .tree {
    padding: 1rem;
  }
}

.treeHeader {
  width: 100%;
  padding-right: 12.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .category {
    width: 18.75rem;
    padding-left: 2.4375rem;
  }

  .content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 70vw;
    .url {
      width: 31.25rem;
    }
    .checkbox {
      width: 7.5rem;
      padding: 0;
    }
  }
}

.checkbox {
  padding: 0 0.9375rem;
  pointer-events: none;
}

.node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .columns {
    width: 18, 75rem;
  }
  font-size: 1rem;
  padding-right: 0.5rem;
  .content {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    .url {
      width: 31.25rem;
    }
    .checkboxWrapper {
      width: 7.5rem;
    }
  }
  .actions {
    display: flex;

    .catalogLink {
      margin: 0 0.5rem;
    }
  }
}
</style>
